import { NavList, NavListItem } from 'components/nav';
import { Skeleton } from 'pages/behavioral';
import { useEffect, useState } from 'react';
import { studentsService } from 'services';
import { AttendanceItem, AttendanceType } from 'services/students/index.models';
import styled from 'styled-components';
import { AttendanceCard } from './cards';

const StyledAttendance = styled.section`
  padding: 20px 0;

  p {
    font-size: 14px;
    font-weight: 400;
    color: #586069;
    padding: 0;
    margin: 0;
  }

  .content {
    padding: 0;
    margin-top: 20px;
    display: flex;

    height: 100%;
    gap: 20px;

    .left {
      flex-basis: 250px;
    }

    .attendance {
      flex: 1;
      &-list {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 15px;
     border-radius: 8px;

      @media (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }
        
    }

    .attendance-container {
      box-shadow: var(--box-shadow);
      background-color: var(--white);
      padding: 15px 20px;
      border-radius: 15px;
      width: 100%;
    }
 &-filters {
      flex: 1;
      max-width: 300px;
      position: sticky;
      top: 0;
      background-color: #586069;
      border-radius: 10px;
      padding: 20px;

      &-label,
      &-all {
        font-size: 16px;
        color: #586069;
      }

      &-label {
        margin-bottom: 10px;
        
      }
    

    .badges {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      flex 1;    
    }
  }
`;

export const Attendance = (data: { student_id: string; class_id: string }) => {
  const [activeAttendance, setActiveAttendance] = useState(AttendanceType.Absent);
  const [attendance, setAttendance] = useState<AttendanceItem[]>([]);
  const [loading, setLoading] = useState(true);

  const AttendanceObject = [
    {
      label: 'Absent Days',
      value: AttendanceType.Absent
    },
    {
      label: 'Excused Days',
      value: AttendanceType.Excused
    }
  ];
  useEffect(() => {
    setLoading(true);
    studentsService.getAttendace(
      {
        ...data,
        type_num: activeAttendance
      },
      res => {
        setAttendance(res);
        setLoading(false);
      }
    );
  }, [activeAttendance, data]);
  return (
    <StyledAttendance>
      <div className="badges-title">Days of Attendance</div>
      <section className="content">
        <div className="left">
          <NavList>
            {AttendanceObject.map(attendance => (
              <NavListItem
                key={attendance.value}
                onClick={() => setActiveAttendance(attendance.value)}
                active={activeAttendance === attendance.value ? 'true' : 'false'}
              >
                {attendance.label}
              </NavListItem>
            ))}
          </NavList>
        </div>
        <div className="attendance">
          <div className="attendance-container">
            {loading ? (
              <div className="attendance-filters-label">
                Please wait...
                <div className="attendance-list">
                  {[...Array(4)].map((_, i) => (
                    <Skeleton width="100%" height="100px" radius="10px" key={i} />
                  ))}
                </div>
              </div>
            ) : attendance.length ? (
              <>
                <div className="attendance-filters-label">
                  Total Days <b>{activeAttendance === AttendanceType.Absent ? 'Absent' : 'Excused'} </b>
                  {attendance.length}
                </div>
                <div className="attendance-list">
                  {attendance.map(item => (
                    <AttendanceCard key={item.id} data={item} />
                  ))}
                </div>
              </>
            ) : (
              <div className="attendace-container">
                <div className="attendance-filters-label">No attendance records found</div>
              </div>
            )}
          </div>
        </div>
      </section>
    </StyledAttendance>
  );
};
