import { useBehavioral } from 'hooks';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
0% { 
 
  opacity: 0.5;
}
50% {
  opacity: 1;
}
`;

export const Skeleton = styled.div<{
  height?: string;
  width?: string;
  radius?: string;
}>`
  height: ${props => props.height ?? '16px'};
  width: ${props => props.width ?? '100%'};
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 
  animation: ${pulse} 1.5s infinite ease-in-out;
  border-radius: ${props => props.radius ?? '4px'};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledBehavioral = styled.div`
  margin 0 auto;
  padding: 20px 0;
  display:flex;
  align-items: flex-start;
  gap: 20px;

  .notes {
  isplay: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0%;
    max-width: 800px;
    max-height: 750px;
    overflow-y: auto;
    box-shadow: var(--box-shadow);
    background-color: var(--white);
    padding: 20px;
    border-radius: 15px;
    min-width: 300px;

    &-title {
      font-size: 16px;
      color: var(--text-color);
      padding:  0;
      margin:0
    }
  }

  .charts {
    flex: 1;
    position: relative;
    max-width: 500px;

    &-title {
      font-size: 20px;
      color: var(--text-color);
      padding:  0;
      padding-bottom: 20px;
      margin:0
    }
  }

  p {
    font-color: var(--text-color);
    font-size: 14px;
  }


`;

const NoteContainer = styled.div<{
  rating: number;
}>`
  padding: 20px;
  margin: 10px 0;
  border-radius: 4px;
  border-radius: 4px;
  background-color: var(--white);
  padding: 8px 12px;
  border: 1px solid var(--border-color);

  .note-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-text);
  }

  .note-body {
    font-size: 14px;
    color: var(--dark-text);
    padding-bottom: 5px;
  }

  .note-date {
    font-size: 12px;
    color: var(--gray-text);
  }
`;
export const Star = styled.span<{
  rating: number;
}>`
  color: ${props => (props.rating <= 2 ? 'red' : props.rating <= 3 ? 'orange' : '#1cbc96')};
`;

export const Behavioral = ({
  data = [],
  student
}: {
  data: Array<{
    id: string;
    name: string;
    email: string;
  }>;
  student: {
    student_id: string;
    class_id: string;
    year: number;
  };
}) => {
  const { notes, fetchingNotes } = useBehavioral(student);
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const ratings = notes.reduce(
      (acc, note) => {
        if (note.rating <= 2) {
          acc.bad += 1;
        } else if (note.rating === 3) {
          acc.good += 1;
        } else if (note.rating > 4) {
          acc.veryGood += 1;
        }
        return acc;
      },
      { bad: 0, good: 0, veryGood: 0 }
    );
    const data = {
      labels: ['Bad', 'Good', 'Very Good'],
      datasets: [
        {
          data: [ratings.bad, ratings.good, ratings.veryGood],
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 206, 86, 0.2)', '#d8eee0'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(255, 206, 86, 1)', '#1cbc96'],
          borderWidth: 1
        }
      ]
    };
    setChartData(data);
  }, [notes]);

  return (
    <StyledBehavioral>
      <div className="notes">
        <div className="notes-title">Student Notes ({notes.length})</div>
        {fetchingNotes ? (
          [...Array(5)].map((_, i) => (
            <NoteContainer className="note" key={i} rating={0}>
              <Skeleton width="30%" />
              <Skeleton width="80%" />
            </NoteContainer>
          ))
        ) : notes.length > 0 ? (
          notes.map(note => (
            <NoteContainer className="note" key={note.id} rating={note.rating}>
              <div className="note-rating">
                {Array(note.rating)
                  .fill(<Star rating={note.rating}>&#9733;</Star>)
                  .map((_, i) => (
                    <Star key={i} rating={note.rating}>
                      &#9733;
                    </Star>
                  ))}
              </div>
              <div className="note-body">{note.note}</div>
              <div className="note-date">{note.date_updated.short_date}</div>
            </NoteContainer>
          ))
        ) : (
          <p>No behavioral notes yet</p>
        )}
      </div>
      <div className="charts">
        <p className="charts-title">Behavioral Chart</p>
        {chartData && chartData.datasets && <Pie data={chartData} />}
      </div>
    </StyledBehavioral>
  );
};
