import { useState, useEffect } from 'react';
import { AttendanceItem } from 'services/students/index.models';
import styled from 'styled-components';
const StyledAttendanceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 8px;
  padding: 12px;

  border: 1px solid var(--green);
  text-align: center;

  .attendance-date {
    font-size: 14px;
    color: var(--hover-color);

    .attendance-day {
      font-weight: bold;
      font-size: 24px;
      color: var(--hover-color);
    }

    .attendance-month {
      font-weight: bold;
      font-size: 16px;
      color: var(--hover-color);
    }

    .attendance-year {
      font-size: 12px;
      color: var(--gray-700);
    }
  }
`;
interface AttendanceCardProps {
  data: AttendanceItem;
}
export const AttendanceCard = ({ data }: AttendanceCardProps) => {
  const [dateObject, setDateObject] = useState<{
    day: number;
    month: string;
    year: number;
  }>({
    day: 0,
    month: '',
    year: 0
  });

  useEffect(() => {
    if (data) {
      const date = new Date(data.date.date);
      const day = date.toLocaleString('en-US', { day: '2-digit' });
      const month = date.toLocaleString('en-US', { month: 'short' });
      const year = date.getFullYear();
      setDateObject({ day: +day, month, year });
    }
  }, [data]);

  return (
    <StyledAttendanceCard>
      <div className="attendance-date">
        <div className="attendance-day">{dateObject.day}</div>
        <div className="attendance-month">{dateObject.month}</div>
        <div className="attendance-year">{dateObject.year}</div>
      </div>
    </StyledAttendanceCard>
  );
};
