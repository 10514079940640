import { UserRole } from 'contexts';
import { ClassItem } from 'pages/schools/models/index.models';

export interface IUploadParentsRequest {
  file_name: string;
  school_id: string;
}

interface IUploadStudentResponse {
  name: string;
  admission_no: string;
}

export interface IUploadParentsResponse2 {
  student: IUploadStudentResponse;
  errors: string[];
}

export interface IUploadParentsResponse {
  message: string;
  data: {
    errors: IUploadParentsResponse2[];
    success: IUploadParentsResponse2[];
  };
}

export interface IStudentParent {
  name: string;
  email: string;
  phone_number: string;
  id?: string;
}

export enum EStudentOnboardingStatus {
  Pending = 1,
  Onboarded = 2
}

export interface IStudent {
  gender: 'F' | 'M' | 'O';
  school_id: string;
  admission_date: IDate;
  name: string;
  class_id: string;
  last_name: string;
  admission_no: string;
  first_name: string;
  parents: IStudentParent[];
  date_updated: IDate;
  date_created: IDate;
  id: string;
  school: {
    id: string;
    name: string;
    email: string;
  };
  class: {
    id: string;
    name: string;
  };
}

export interface IUpdateStudent {
  first_name?: string;
  last_name?: string;
  admission_no?: string;
  parens?: {
    name: string;
    email: string;
  }[];
  gender?: 'F' | 'M' | 'O';
}

export interface IDate {
  long_date: string;
  short_date: string;
  time_since: string;
}

interface IAddStudentParent {
  name: string;
  email: string;
}

export interface IAddStudent {
  first_name: string;
  last_name: string;
  admission_no: string;
  admission_date: string;
  gender?: 'F' | 'M' | 'O';
  parents?: IAddStudentParent[];
}

export interface IAddStudentPayload {
  class_id: string;
  school_id: string;
  student: IAddStudent;
}

export interface IAddStudentResponse {
  message: string;
  data: { id: string; name: string; admission_no: string };
}

export interface IMoveStudentsRequest {
  school_id: string;
  class_id: string;
  next_class_id: string;
  students: string[];
  move_all?: boolean;
}

export interface IMoveStudentsResponse {
  data: {
    count: number;
  };
  message: string;
}

export interface ClassItemrowProps {
  item: ClassItem;
  school_id: string;
  role: UserRole;
  setEditClass?: () => void;
  setClasses: (classes: ClassItem[]) => void;
}
export interface DateObject {
  long_date: string;
  short_date: string;
  time_since: string;
  date: string;
}
export enum AttendanceType {
  Absent = 1,
  Excused = 2
}

export enum AttendanceDuration {
  HalfDay = 1,
  FullDay = 2
}

export interface GetAttendanceParams {
  student_id?: string;
  class_id: string;
  year?: string | number;
  type: AttendanceType;
  date_created?: string;
}

export interface AttendanceItem {
  id: string;
  students: AttendanceStudent[];
  class_id: string;
  school_id: string;
  date: DateObject;
  date_created: DateObject;
  date_updated: DateObject;
  type: AttendanceType;
  duration: AttendanceDuration;
  term: string;
  remarks?: string;
}

export interface AttendanceStudent {
  id: string;
  attendance_id: string;
  name: string;
  gender: string;
  photo_url: string;
}

export interface AddAttendancePayload {
  students: string[];
  class_id: string;
  school_id: string;
  type: AttendanceType;
  date: string;
  duration: AttendanceDuration;
  term: string;
  remarks?: string;
}
