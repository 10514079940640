import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';

import { CounterLabel } from '@primer/react';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { IRankingPieData } from '../../index.model';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Students Ranking Summary'
    }
  }
};

const StyledResultsSummary = styled.div`
  .results-summary {
    &-content {
      display: inline-flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    &-item {
      max-width: 400px;
    }

    &-title {
      gap: 5px;
      color: var(--text-color);
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 10px;
    }

    &-description {
      font-size: 0.85rem;
      color: var(--gray-text);
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
`;

export const ResultsSummary = ({ data }: { data: IRankingPieData[] }) => {
  return (
    <StyledResultsSummary>
      <div>
        <div className="results-summary-title flex">
          <CounterLabel>1</CounterLabel> <span>Category Performance</span>
        </div>
        <div className="results-summary-description">
          Based on the average scores of students in the class, the following chart shows the performance of students in
          different categories.
        </div>
        <div className="results-summary-content">
          {data.map(item => (
            <div key={item.title} className="results-summary-item">
              <Pie
                data={{
                  labels: item.labels,
                  datasets: [
                    {
                      data: item.pie_data,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                options={{
                  ...options,
                  plugins: {
                    ...options.plugins,
                    title: {
                      display: true,
                      text: item.title
                    }
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </StyledResultsSummary>
  );
};
