import axios from 'axios';
import {
  ClassItem,
  ICreateClassResponse,
  IGetClassResponse,
  IGetClassesResponse,
  IGetSchoolRequestQuery,
  SchoolItem,
  CreateSchoolPayload,
  SchoolItemCreateResponse,
  UpdateSchoolRequest,
  UpdateSchoolResponse,
  IUpdateClassPayload
} from 'pages/schools/models/index.models';
import { dbService } from 'services';
import { SharedService } from 'services/shared';
import { IAddAdminPayload, CurriculumItemClass, IGetAdminsRequest, IGetAdminsResponse } from '../../models/index.model';
import { INewsLetterFile } from '../newsletters/index.models';
import { EPermission, ERole } from 'pages/manage/index.models';

class CoreService extends SharedService {
  uploadedFiles: INewsLetterFile[] = [];

  async getCurriculums(slug?: string) {
    try {
      const idToken = await this.getIdToken();
      const res = slug
        ? await axios.get(`${this.getApiBase()}/curriculum/${slug}/`, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          })
        : await axios.get(`${this.getApiBase()}/curriculum/`, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          });
      return res && res.data ? res.data : [];
    } catch (error) {
      throw error;
    }
  }

  async createUser(data: {
    email: string;
    first_name: string;
    last_name: string;
    permission?: EPermission;
    role: ERole;
  }) {
    try {
      const res = await axios.post(`${this.getApiBase()}/account/create`, data, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      return res && res.data
        ? {
            user: res.data.data,
            message: res.data.message
          }
        : {
            user: null,
            message: res.data.message
          };
    } catch (error) {
      throw error;
    }
  }

  async uploadFile(
    payload: { file: File; uid: string; file_name?: string },
    callback: { (downloadurl: string): Promise<void>; (arg0: string): void }
  ) {
    try {
      const ref = dbService.ref(
        dbService.storage,
        `media/${payload.uid}/images/${payload.file_name ?? payload.file.name.toLocaleLowerCase().split(' ').join('-')}`
      );
      const uploadTask = dbService.uploadBytesResumable(ref, payload.file);
      uploadTask.on(
        'state_changed',
        () => {},
        error => {
          throw error;
        },
        async () => {
          const downloadurl = await dbService.getDownloadURL(uploadTask.snapshot.ref);
          callback(downloadurl);
        }
      );
    } catch (error) {
      throw error;
    }
  }

  async uploadFiles(
    files: File[],
    uid: string,
    bucket: string,
    bucketFolder: string,
    callback: {
      (res: {
        files: {
          url: string;
          type: string;
          name: string;
        }[];
        uploading: boolean;
      }): any;
    }
  ) {
    try {
      this.uploadedFiles = [];
      for (const file of files) {
        const file_name = file.name.toLocaleLowerCase().split(' ').join('-');
        const ref = dbService.ref(dbService.storage, `${bucket}/${uid}/${bucketFolder}/${file_name}`);
        const uploadTask = dbService.uploadBytesResumable(ref, file);
        uploadTask.on(
          'state_changed',
          () => {},
          error => {
            throw error;
          },
          async () => {
            const downloadurl = await dbService.getDownloadURL(uploadTask.snapshot.ref);
            this.uploadedFiles.push({
              url: downloadurl,
              type: file.type,
              name: file_name
            });
            const filesCount = files.length - 1;
            const currentIndex = files.indexOf(file);
            callback({
              files: this.uploadedFiles,
              uploading: currentIndex < filesCount
            });
          }
        );
      }
    } catch (error) {
      throw error;
    }
  }

  async createSchool(school: CreateSchoolPayload, callback: (uid: string) => void) {
    try {
      const { photo, ...schoolPayload } = school;
      const res = await axios.post<SchoolItemCreateResponse>(
        `${this.getApiBase()}/schools/`,
        { ...schoolPayload, photo_url: 'https://placehold.it/200x200' },
        {
          headers: {
            Authorization: `Bearer ${await this.getIdToken()}`
          }
        }
      );
      if (res.data.data && res.data.data.id) {
        this.uploadFile(
          {
            file: photo,
            uid: res.data.data.id
          },
          async downloadurl => {
            this.updateSchool(res.data.data.id, { photo_url: downloadurl }, () => callback(res.data.data.id));
          }
        );
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      throw error;
    }
  }

  async getSchools(query: IGetSchoolRequestQuery, callback: (arg0: SchoolItem[]) => void) {
    const queryStr = this.getQueryString(query);
    const idToken = await this.getIdToken();
    const res = await axios.get(`${this.getApiBase()}/schools/?${queryStr}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getSchoolById(id: string) {
    const res = await axios.get(`${this.getApiBase()}/schools/${id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) return res.data.data;
    throw new Error(res.data.message);
  }

  async updateSchool(id: string, data: UpdateSchoolRequest, callback: (arg0: UpdateSchoolResponse) => void) {
    try {
      const res = await axios.patch<UpdateSchoolResponse>(`${this.getApiBase()}/schools/${id}`, data, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      callback(res.data);
    } catch (error) {
      throw error;
    }
  }

  async updateSchoolFeatures(
    data: {
      school_id: string;
      features: string[];
    },
    callback: (arg0: { data: string[]; message: string }) => void
  ) {
    try {
      const res = await axios.patch<{
        data: string[];
        message: string;
      }>(`${this.getApiBase()}/features/school`, data, {
        headers: {
          Authorization: `Bearer ${await this.getIdToken()}`
        }
      });
      callback(res.data);
    } catch (error) {
      throw error;
    }
  }

  async deleteSchool(id: string, callback: (arg0: { data: string; message: string }) => void) {
    const deleteRes = await axios.delete(`${this.getApiBase()}/schools/${id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (deleteRes.data.data) callback(deleteRes.data);
    else throw new Error(deleteRes.data.message);
  }

  async createClass(data: CurriculumItemClass, callback: (arg0: ICreateClassResponse) => void) {
    const res = await axios.post<ICreateClassResponse>(`${this.getApiBase()}/classes/`, data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data && res.data.data.id) callback(res.data);
    else throw new Error(res.data.message);
  }

  async updateClass(payload: IUpdateClassPayload, callback: (arg0: { data: string; message: string }) => void) {
    payload.data = Object.entries(payload.data).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') return { ...acc, [key]: value };
      return acc;
    }, {} as IUpdateClassPayload['data']);
    const res = await axios.patch<{
      data: string;
      message: string;
    }>(`${this.getApiBase()}/classes/${payload.id}`, payload.data, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data);
    else throw new Error(res.data.message);
  }

  async getClassById(school_id: string, id: string, callback: (arg0: ClassItem) => void) {
    const res = await axios.get<IGetClassResponse>(`${this.getApiBase()}/classes/school/${school_id}/${id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async getClassesBySchoolId(school_id: string, callback: (arg0: ClassItem[]) => void) {
    const res = await axios.get<IGetClassesResponse>(`${this.getApiBase()}/classes/school/${school_id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data.data);
  }

  async deleteClassById(school_id: string, id: string, callback: (arg0: { data: string; message: string }) => void) {
    const res = await axios.delete<{
      data: string;
      message: string;
    }>(`${this.getApiBase()}/classes/${school_id}/${id}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (!res.data.data) throw new Error(res.data.message);
    callback(res.data);
  }

  async addAdminUser(payload: IAddAdminPayload, callback: (arg0: { data: string; message: string }) => void) {
    const res = await axios.post<{
      data: string;
      message: string;
    }>(`${this.getApiBase()}/account/admins`, payload, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data);
    else throw new Error(res.data.message);
  }

  async getAdminUsers(payload: IGetAdminsRequest, callback: (arg0: IGetAdminsResponse) => void) {
    const query = this.getQueryString(payload);
    const res = await axios.get<IGetAdminsResponse>(`${this.getApiBase()}/account/admins?${query}`, {
      headers: {
        Authorization: `Bearer ${await this.getIdToken()}`
      }
    });
    if (res.data.data) callback(res.data);
    else throw new Error(res.data.message);
  }

  getYearsFromNow(count: number) {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i > currentYear - count; i--) {
      years.push(i);
    }
    return years;
  }

  generateFileFromData(data: string, filename: string, type: string) {
    const decodedString = atob(data);
    const blob = new Blob([decodedString], { type: type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

  getTerms() {
    return [
      {
        id: 1,
        name: 'First Term'
      },
      {
        id: 2,
        name: 'Second Term'
      },
      {
        id: 3,
        name: 'Third Term'
      }
    ];
  }

  validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return email.length > 3 ? re.test(email) : true;
  }

  async signIn(email: string, password: string) {
    try {
      const res = await dbService.signInWithEmailAndPassword(dbService.auth, email, password);
      return res.user;
    } catch (error) {
      throw error;
    }
  }

  async signOut() {
    try {
      await dbService.auth.signOut();
    } catch (error) {
      throw error;
    }
  }
}

const coreService = new CoreService();

export default coreService;
